<template>
  <div class="agenda">
    <!-- page -->
    <AppointmentsTab v-if="$route.query.tab === 'appointments'" />
    <ConferencesTab v-if="$route.query.tab === 'conferences'" />
  </div>
</template>

<script>
import ConferencesTab from './tabs/ConferencesTab'
import AppointmentsTab from './tabs/AppointmentsTab'

export default {
  name: 'Agenda',
  components: {
    AppointmentsTab,
    ConferencesTab
  },
  mounted () {
    if (!this.$route.query.tab) {
      this.$router.replace({
        name: 'Agenda',
        query: {
          tab: 'appointments'
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.agenda
  padding-top: 50px
</style>
