<template>
  <div>
    <!-- loader -->
    <template v-if="loading">
      <div class="page-segment margin-top">
        <div class="page-segment-content">
          <div class="margin-bottom">
            <Skeleton width="230px" height="30px" borderRadius="16px" />
          </div>
          <div class="margin-bottom">
            <Skeleton width="180px" height="24px" borderRadius="16px" />
          </div>
          <div class="margin-bottom-sm" v-for="index in 3" :key="index">
            <Skeleton width="100%" height="97px" borderRadius="16px" />
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="conferences && !loading">
      <!-- upcoming conferences -->
      <div class="page-segment margin-top" v-if="conferences && conferences.upcoming && Object.keys(conferences.upcoming).length > 0">
        <div class="page-segment-content">
          <AppointmentGroup
            :title="$translations.conferences['next-conferences']"
            :conferences="conferences.upcoming"
          />
        </div>
      </div>

      <!-- past conferences -->
      <div class="page-segment margin-top" v-if="conferences && conferences.past && Object.keys(conferences.past).length > 0">
        <div class="page-segment-content">
          <AppointmentGroup
            :title="$translations.conferences['past-conferences']"
            :disabled="true"
            :conferences="conferences.past"
          />
        </div>
      </div>
    </template>

    <!-- data zero -->
    <DataZeroSimple
      v-if="showDataZero"
      :text="datazero.text"
      :image="datazero.image"
      :buttons="datazero.buttons"
    />
  </div>
</template>

<script>
import { Skeleton, NoEvents } from '@seliaco/red-panda'
import AppointmentGroup from '../components/AppointmentGroup'
import DataZeroSimple from '@/components/data-zeros/DataZeroSimple'
import { mapGetters } from 'vuex'

export default {
  name: 'ConferencesTab',
  components: { Skeleton, AppointmentGroup, DataZeroSimple },
  data () {
    return {
      datazero: {
        text: this.$translations.conferences['empty-state'].text,
        image: NoEvents,
        buttons: [
          {
            text: this.$translations.conferences['empty-state']['begin-now'],
            type: 'outline',
            redirect: '/conferences'
          }
        ]
      }
    }
  },
  created () {
    this.$store.dispatch('conferences/getAppointmentConferences', { user: this.user })
  },
  computed: {
    ...mapGetters({
      conferences: 'conferences/getAppointmentConferences',
      loading: 'conferences/getLoading',
      user: 'auth/user'
    }),
    showDataZero () {
      return this.conferences && this.conferences.upcoming && this.conferences.past && (
        Object.keys(this.conferences.upcoming).length === 0 && Object.keys(this.conferences.past).length === 0
      )
    }
  }
}
</script>

<style>
</style>
